import styled from '@emotion/styled'
import { BannerCTA } from 'app/components/BannerCTA'
import { Hero } from 'app/components/Hero'
import { Intro } from 'app/components/Intro'
import { MeetingRoomsList } from 'app/components/MeetingRoomsList'
import { SEO } from 'app/components/SEO'
import { ServicesList } from 'app/components/ServicesList'
import { Footer } from 'app/containers/Footer'
import { GoogleAnalytics } from 'app/containers/GoogleAnalytics'
import { Header, Props as HeaderProps } from 'app/containers/Header'
import { MeetingForm } from 'app/containers/MeetingForm'
import { StructuredData } from 'app/containers/StructuredData'
import {
  MeetingFormSenderBackend,
  MeetingFormSenderBackendConfiguration,
} from 'app/utils/MeetingFormSender'
import { PageProps } from 'gatsby'
import React, { memo, useMemo } from 'react'

import { Props } from './contents'

export interface PageContext {
  id?: string
  languageCode: string
  languagePrefix: string | null
  headerProps?: HeaderProps
  meetingFormSenderBackendConfiguration: MeetingFormSenderBackendConfiguration
}

export interface Context extends PageContext {
  props: Props
}

export default memo(function MeetingPageTemplate({
  pageContext,
}: PageProps<void, Context>) {
  const context = pageContext as any
  const sendFormToBackend = useMemo(
    () =>
      MeetingFormSenderBackend(
        pageContext.meetingFormSenderBackendConfiguration,
      ),
    [pageContext.meetingFormSenderBackendConfiguration],
  )

  return (
    <Container>
      <SEO {...context.seoProps} />
      <GoogleAnalytics />
      {pageContext.headerProps ? (
        <Header
          pageID={pageContext.id}
          languageCode={pageContext.languageCode}
          languagePrefix={pageContext.languagePrefix}
          {...pageContext.headerProps}
        />
      ) : null}
      {context.heroProps ? <Hero {...context.heroProps} /> : null}
      {context.introProps ? <Intro {...context.introProps} /> : null}
      {context.servicesListProps ? (
        <ServicesList {...context.servicesListProps} />
      ) : null}
      {context.meetingRoomsListProps ? (
        <MeetingRoomsList {...context.meetingRoomsListProps} />
      ) : null}
      <MeetingForm
        {...(context.meetingFormProps ? context.meetingFormProps : null)}
        onMeetingFormSendToBackend={async (data) =>
          await sendFormToBackend(data)
        }
      />
      {context.bannerCTAProps ? (
        <BannerCTA {...context.bannerCTAProps} />
      ) : null}
      <Footer
        languageCode={pageContext.languageCode}
        languagePrefix={pageContext.languagePrefix}
      />
      <StructuredData languageCode={pageContext.languageCode} />
    </Container>
  )
})

const Container = styled.main``
