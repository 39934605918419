import styled from '@emotion/styled'
import { uniq } from 'lodash'
import React, { memo } from 'react'

import { MeetingRoom, Props as MeetingRoomProps } from './MeetingRoom'

export interface Props {
  meetingRooms: MeetingRoomProps[]
}

export const MeetingRoomsList = memo(function MeetingRoomsList({
  meetingRooms,
}: Props) {
  if (meetingRooms.length < 1) {
    return null
  }

  return (
    <Container>
      {uniq(meetingRooms).map((item, index) => (
        <MeetingRoom key={index} {...item} />
      ))}
    </Container>
  )
})

const Container = styled.section``
